<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column layout-align-start-center">
        <div class="row-1 row layout-column layout-align-end-center">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative single-line"
            :show="showTitle"
          ></section-title>
        </div>
        <div class="row-2 row layout-row cols layout-align-center-start">
          <div class="col-1 layout-row layout-align-end-start">
            <video-box
              id="video-healinghouse"
              class="parallax-item parallax-relative section-videobox"
              data-depth="2.5"
              :show="showVideoBox"
              :data="data.boxes.video"
              :timescale="0.95"
              :timescaleReverse="1.5"
            ></video-box>
            <info-modal-box
              id="box-healinghouse"
              :show="showInfoBoxes"
              class="parallax-item parallax-relative section-infobox"
              data-depth="3.8"
              :data="data.boxes.info_1"
              :timescale="0.9"
            ></info-modal-box>
          </div>
          <div class="col-2 layout-align-start-start layout-column">
            <quote
              :data="data.boxes.quote"
              :fontSize="1.7"
              :maxWidth="'16em'"
              :overlayOpacity="0.3"
              :show="showQuote"
              data-depth="4"
              class="parallax-item parallax-relative"
              :timescale="0.7"
              :splitLines="true"
            ></quote>
          </div>
        </div>
      </div>
    </div>

    <background-video-full
      :id="'CookedSparseAntipodesgreenparakeet'"
      :show="showBackground"
      :mask="true"
      :maskOpacity="0.85"
      :poster="data.images.backgroundVideoPoster"
      :timescale="1.5"
    ></background-video-full>

    <div id="container-lions" class="container absolute fluid z-index-0">
      <decal
        id="decal-lions"
        class="absolute"
        :show="showMisc"
        :image="data.images.lions"
        :customTimeline="timelines.timelineLions"
      ></decal>
    </div>

    <div id="container-yoga" class="container absolute z-index-1">
      <decal
        id="decal-yoga"
        class="absolute"
        :show="showDecal"
        :image="data.images.yoga"
        :customTimeline="timelines.timelineYoga"
        :parallaxDepth="3.2"
      ></decal>
    </div>

    <div id="container-pattern" class="fixed fluid container z-index-2">
      <pattern-overlay
        :show="showPattern"
        :image="data.images.pattern1"
        :align="'right'"
        id="pattern1"
      ></pattern-overlay>

      <pattern-overlay
        :show="showPattern"
        :image="data.images.pattern2"
        id="pattern2"
      ></pattern-overlay>
    </div>

    <modal-lightbox></modal-lightbox>
    <modal-video></modal-video>
    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import ModalLightbox from "@/components/ui/ModalLightbox.vue";
import VideoBox from "@/components/ui/VideoBox.vue";
import ModalVideo from "@/components/ui/ModalVideo.vue";
import Quote from "@/components/ui/Quote.vue";
import PatternOverlay from "@/components/ui/PatternOverlay.vue";
import BackgroundVideoFull from "@/components/ui/BackgroundVideoFull.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-4-1",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Decal,
    InfoModalBox,
    ModalLightbox,
    ModalVideo,
    VideoBox,
    Quote,
    PatternOverlay,
    BackgroundVideoFull,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 100],
        ["showBackground", 1200],
        ["showTitle", 500],
        ["showQuote", 100],
        ["showInfoBoxes", 470],
        ["showDecal", 0],
        ["showPattern", 0],
        ["showMisc", 140],
        ["showVideoBox", 1200],
      ],
      timelines: {
        timelineYoga: {
          duration: 1.2,
          animations: {
            ease: "Power1.easeInOut",
            autoAlpha: 0,
            y: "+=17%",
            scale: "0.9",
          },
        },
        timelineLions: {
          duration: 1.2,
          animations: {
            ease: "Power1.easeInOut",
            autoAlpha: 0,
            x: "-=18%",
            scale: "0.8",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.row-1 {
  margin-bottom: 0.5rem;
  padding: var(--col-padding);
  width: 100%;
}
.row-2 {
  margin-bottom: 4rem;
  padding: var(--col-padding);
}
.col-1 {
  padding-right: 9vw;
  margin-bottom: 1rem;
}
.col-2 {
  padding-left: 8vw;
  margin-top: 1.1rem;
}

.section-title {
  margin-right: 40%;
}

#box-healinghouse {
  z-index: 2;
  margin: 2rem 0 0 0;
}
#video-healinghouse {
  z-index: 1;
  margin-right: -2.5rem;
}
/deep/ .video-box-image img {
  filter: none;
}

#decal-lions {
  bottom: -1rem;
  left: -1%;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) -1.5rem 3rem 1.9rem);
  margin: 0;
  max-width: 40vw;
  width: 760px;
  min-width: 490px;
}

/deep/ #decal-lions img {
}

#decal-yoga {
  bottom: -1rem;
  left: 44.5%;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) -1.5rem 5rem 1.9rem);
  margin: 0;
  max-width: 24vw;
  min-width: 360px;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.landscape #decal-yoga {
  justify-content: center;
}

/deep/ #decal-yoga img {
  max-height: 93vh;
}

#pattern2 {
  left: -10%;
}

/deep/ .section-quote .quote-overlay {
  padding-right: 0;
}

/deep/ #box-healinghouse .info-box {
  background: rgba(255, 255, 255, 0.8);
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .section-quote {
    font-size: 0.9rem;
  }
  .col-1 {
    padding-right: 7vw;
  }
  .col-2 {
    padding-left: 7.5vw;
  }
}

@media screen and (max-width: 1200px) {
  .row-1 {
    margin-bottom: 0;
  }
  .row-2 {
    flex-direction: column;
    margin-bottom: 7rem;
  }
  .col-1 {
    order: 2;
    padding: 0;
    margin-left: 7%;
  }
  .col-2 {
    order: 1;
    padding: 0;
    margin: 0.8rem 0 1.5rem 3%;
  }
  #container-yoga {
    position: fixed;
    height: 100vh;
  }
  #decal-yoga {
    left: auto;
    right: 0.5%;
    max-width: 40vw;
  }
  .section-quote {
    font-size: 0.8rem;
  }
  /deep/ .section-quote .quote-quote {
    width: 30em !important;
  }
  #decal-lions {
    max-width: 66vw;
    min-width: 400px;
    left: -2.5rem;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  .portrait #decal-lions {
    left: -4.5rem !important;
  }
}

@media screen and (max-width: 767px) {
  .portrait #pattern2 {
    left: -1%;
  }
  .col-1 {
    margin-left: 0;
  }
  .col-2 {
    margin-left: 0;
    margin-bottom: 1.1rem;
    margin-top: 0.6rem;
  }

  /deep/ .section-quote .quote-quote {
    width: 24em !important;
  }
  #video-healinghouse {
    margin-right: -3.5rem;
  }

  .landscape #decal-yoga {
    right: 0;
    max-width: 36vw;
    bottom: -10vh;
  }

  .landscape .section-quote {
    font-size: 9px;
  }

  .portrait #decal-yoga {
    right: -3%;
  }
  .portrait .section-quote {
    font-size: 10px;
  }
}

@media screen and (max-width: 680px) {
  .portrait #decal-yoga {
    right: -5%;
  }
}

@media screen and (max-width: 639px) {
  .portrait .row-2 {
    align-items: center;
  }
  .portrait .col-2 {
    margin-right: var(--col-padding-adjust);
  }
  .portrait .section-title {
    margin-right: var(--col-padding-adjust);
  }
  .portrait .col-1 {
    align-self: flex-start;
    margin-left: 5%;
  }
  .portrait #decal-yoga {
    max-width: 55vw;
    min-width: 200px;
    right: 0;
  }
  .portrait #decal-lions {
    left: -10rem !important;
  }
  .portrait .row-2 {
    margin-bottom: 7.5rem;
  }
}

@media screen and (max-width: 620px) {
  .portrait .col-1 {
    flex-direction: column;
  }
  .portrait #decal-yoga {
    right: -4%;
  }
  .portrait #video-healinghouse {
    margin: 0;
  }
  .portrait #box-healinghouse {
    margin: 0;
  }
  .portrait .section-quote {
    font-size: 9px;
  }
}

@media screen and (max-width: 540px) {
  .portrait .col-1 {
    margin-left: 0;
  }
}
@media screen and (max-width: 520px) {
}

@media screen and (max-width: 479px) {
  .portrait .section-quote {
    font-size: 8.5px;
  }
  .portrait #video-healinghouse {
    order: 2;
  }
  .portrait #box-healinghouse {
    order: 1;
  }
  .portrait .row-2 {
    margin-bottom: 8.5rem;
  }
  .portrait #decal-lions {
    min-width: 380px;
    left: -11rem !important;
  }
}

@media screen and (max-width: 420px) {
  .portrait .col-1 {
    align-self: center;
  }
  .portrait #decal-yoga {
    right: -3%;
    max-width: 55vw;
  }
  .portrait #decal-lions {
    left: -12rem !important;
  }
}

@media screen and (max-width: 390px) {
  .portrait #decal-lions {
    left: -14rem !important;
  }
  .portrait .section-quote {
    font-size: 8.1px;
  }
}
</style>
