<template>
  <div class="section-background fixed part-component section-background-video">
    <div
      v-if="mask"
      ref="mask"
      class="section-nav-mask overlay layout-column"
      :style="{ opacity: maskOpacity }"
    >
      <div class="section-header-mask"></div>
      <div class="section-navigation-mask"></div>
      <div class="section-background-mask" ref="backgroundMask"></div>
      <div class="section-footer-mask"></div>
    </div>

    <div class="section-background-video overlay" ref="videoWrap">
      <video
        :poster="poster"
        id="video"
        preload="none"
        autoplay
        loop
        muted
        ref="video"
      >
        <source :src="`${$urls.video + id}.webm`" type="video/webm" />
        <source :src="`${$urls.video + id}.mp4`" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { partComponent } from "@/mixins/core.js";

export default {
  name: "BackgroundVideoFull",
  mixins: [partComponent],
  props: ["id", "mask", "maskOpacity", "poster"],
  data() {
    return {};
  },
  computed: {
    ...mapState(["viewReady", "menuOpen", "modalOpen"]),
  },
  watch: {
    menuOpen(val) {
      if (!val && this.leavingView) {
        return;
      } else {
        this.pauseVideo(val);
      }
    },
    modalOpen(val) {
      this.pauseVideo(val);
    },
  },
  methods: {
    pauseVideo(val) {
      if (val) {
        this.$refs.video.pause();
      } else {
        this.$refs.video.play();
      }
    },
    createTimeline() {
      const { mask, videoWrap } = this.$refs;

      //enter timeline
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        videoWrap,
        3,
        {
          autoAlpha: 0,
          ease: Power2.easeInOut,
        },
        0
      ).from(
        mask,
        3,
        {
          autoAlpha: 0.8,
          ease: Power2.easeIn,
        },
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.section-background-mask {
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 0.8) 60%,
    rgb(255, 255, 255) 100%
  );
}
</style>
