<template>
  <div
    class="
      section-quote
      part-component
      parallax-item parallax-flex
      layout-column
    "
  >
    <div
      class="quote-overlay"
      :style="{
        fontSize: `${fontSize}em`,
      }"
    >
      <div
        class="quote-overlay-background"
        ref="overlay"
        :style="{
          background: `rgba(245,245,245, ${overlayOpacity})`,
        }"
      ></div>
      <div ref="line" class="quote-line absolute"></div>
      <blockquote
        ref="quote"
        class="quote-quote"
        :style="{ width: maxWidth, lineHeight: lineHeight }"
      >
        <span class="no-select quote-text">
          {{ data.text }}
        </span>
      </blockquote>
    </div>
    <div ref="person" class="quote-person">
      <span v-if="data.person">{{ data.person }}</span>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "Quote",
  mixins: [partComponent],
  props: {
    data: {
      type: Object,
    },
    fontSize: {
      default: 3,
    },
    maxWidth: {
      default: "100%",
    },
    lineHeight: {
      default: 1.9,
    },
    overlayOpacity: {
      default: 0.5,
    },
    splitLines: {
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { overlay, quote, person, line } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      let splitType = this.splitLines ? "lines" : "words";
      let timescaleMultiplier = this.splitLines ? 1.4 : 1;
      let split = new SplitText(".quote-text", {
        type: splitType,
        position: "relative",
      });

      tl.from(
        quote,
        1.7,
        {
          color: "transparent",
          ease: Power2.easeOut,
          x: "+=7%",
        },
        0
      )
        .staggerFrom(
          split[splitType],
          0.6 * timescaleMultiplier,
          {
            y: "+=10",
            autoAlpha: 0,
            ease: Power2.easeOut,
          },
          0.07 * timescaleMultiplier,
          0
        )
        .add("quoteIn")
        .from(
          line,
          0.5,
          {
            height: 0,
          },
          "quoteIn-=1"
        )
        .from(
          person,
          0.8,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=15",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          "quoteIn-=1.2"
        )
        .from(
          overlay,
          1,
          {
            backgroundColor: "transparent",
            ease: Power0.noEase,
          },
          0
        );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.section-quote {
  width: 100%;
  font-size: 1rem;
}
.quote-overlay {
  padding: 0 1.25em 0 1.875em;
  position: relative;
}
.quote-overlay-background {
  background: rgba(245, 245, 245, 0.4) none repeat scroll 0% 0%;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.quote-line {
  width: 4px;
  height: calc(100% - 0.6em);
  background: rgba(0, 0, 0, 0.05);
  left: 0;
  top: 0.32em;
}
.quote-person {
  padding: 1em 0 0 3em;
  color: var(--grey2);
  font-style: italic;
}
.quote-person span {
  font-size: 1.1rem;
}
.quote-quote::before,
.quote-quote::after {
  position: absolute;
  font-size: 2.75em;
  max-width: 100%;
}
.quote-quote::before {
  content: open-quote;
  top: -1.023em;
  left: -0.75em;
}
.quote-quote::after {
  content: close-quote;
  bottom: -1.636em;
  right: 0;
}
.quote-quote {
  color: #333;
}
.quote-quote span {
  color: #333 !important;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1200px) {
  .quote-line {
    width: 3px;
  }
}
@media screen and (max-width: 767px) {
  .quote-person span {
    font-size: 1rem;
  }
  .quote-line {
    width: 2px;
  }
}
@media screen and (max-width: 420px) {
  .quote-person span {
    font-size: 0.9rem;
  }
}
</style>
